.notfound-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    font-family: 'Roboto', sans-serif;
    color: #333;
    position: relative; 
}

.logo-container {
    position: absolute;
    top: 20px; 
    left: 20px; 
}

.app-logo {
    margin-top: -35%;
    width: 230px; 
    height: auto;
}


.notfound-title {
    font-size: 120px;
    font-weight: bold;
    color: #ff6b6b;
    margin: 0;
}

.notfound-subtitle {
    font-size: 36px;
    margin-top: 10px;
    color: #333;
}

.notfound-text {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #666;
    max-width: 500px;
}

.notfound-button {
    background-color: #ff6b6b;
    color: white;
    padding: 12px 25px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.notfound-button:hover {
    background-color: #ff4b4b;
    text-decoration: none;
}
