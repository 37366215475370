#terms-container {
    max-width: 800px;
    margin: 0 auto;
    /* padding: 20px; */

}

#terms-section {
    margin-bottom: 20px;
}

#heading {
    font-size: 24px;
    font-weight: bold;
    font-family: Lato, sans-serif !important;

}

#heading2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

#paraTag {
    font-size: 16px;
    line-height: 1.5;
    font-family: Lato, sans-serif !important;
}

#topHead {
    font-size: 32px;
    margin-left: 214px;
    /* background: black; */
    color: black;
    width: fit-content;
}

#footerContainer {
    padding: 25px;
    background-color: black;
    color: white;
}

#link {
    text-decoration: none;
}

#headerPrivacy {
    font-size: 20px;
    text-align: center;
    justify-content: center;
    background: #000;
    color: white;
    width: 100%;
    padding: 20px 0;
    z-index: 0;
}

#linkcomponent {
    color: #febba0 !important;
    text-decoration: none;
}

#RingLogo1 {
    margin: 16px;
    margin-left: 71px;
}

#headertext {
    margin-left: -820px;
}

#ringlogo1 {
    margin-bottom: -272px;
}

#ringlogo2 {
    margin-left: 1301px;
    margin-top: -227px;
}