@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@font-face {
    font-family: HK Nova;
    src: url("./utils/fonts/HKNova-Medium.ttf");
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'HK Nova', 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: overlay;
  }
  

  /* styles.css (or any other appropriate stylesheet) */
.infinite-scroll-component {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: #481BEB;
  border-radius: 6px;
}
