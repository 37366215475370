/* DraftEditor */
.demo-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: auto;
    max-height: 400px;
}

.rdw-emoji-modal::-webkit-scrollbar,
.rdw-editor-main::-webkit-scrollbar,
.rdw-image-modal-upload-option-label::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

.rdw-emoji-modal::-webkit-scrollbar-thumb,
.rdw-editor-main::-webkit-scrollbar-thumb,
.rdw-image-modal-upload-option-label::-webkit-scrollbar-thumb {
    background-color: rgba(26, 26, 26, 0.1);
    border-radius: 6px;
}

.rdw-image-modal-upload-option {
    overflow: hidden;
    outline-offset: -10px;
}

.rdw-image-modal-upload-option-label {
    width: 75%;
    overflow: auto;
    font-size: 12px;
}
  
.rdw-emoji-modal {
    left: -154px;
}
.notranslate.public-DraftEditor-content figure {
    margin-left: 0;
}


/* FroalaEditor */
.fr-box.fr-basic {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
}
.fr-box.fr-basic.fr-top .fr-wrapper {
    height: 280px;
    overflow: auto;
}

.fr-box.fr-basic.fr-top .fr-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

.fr-box.fr-basic.fr-top .fr-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(26, 26, 26, 0.1);
    border-radius: 6px;
}

.fr-second-toolbar:empty {
    display: none;
}
.fr-toolbar.fr-top {
    top: 0;
    border-bottom: 1px solid inherit;
    border-radius: 0 0 10px 10px !important;
    -moz-border-radius: 0 0 10px 10px !important;
    -webkit-border-radius: 0 0 10px 10px !important;
    background-color: #ffffff00;
}
.fr-second-toolbar,
.fr-wrapper:first-child {
display: none !important;
}

.fr-box.fr-basic.fr-top .fr-wrapper:first-child {
    display: none;
}

.hide-toolbar{
    display:none !important;
}